
import module from '@/mixins/module';
import { quoteShape } from '@/types/modules';

export default {
  mixins: [module],
  inheritAttrs: false,
  props: {
    ...quoteShape,
  },
  computed: {
    /**
     * When this module is rendered in sidebar (on the project detail page) then never render the aside image
     */
    visualProps() {
      if (this.compact) {
        return null;
      }

      return this.$first(this.visual);
    },
    contentClasses() {
      if (this.compact) {
        return null;
      }

      if (!this.visualProps && this.textAlignment === 'center') {
        return ['l:col-start-4', 'l:col-span-6'];
      }

      return ['l:col-start-5', 'l:col-span-6'];
    },
  },
};
